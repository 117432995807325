export const green = {
  50: '#ffffff',
  100: '#e2f5ea',
  200: '#b8e6cc',
  300: '#83d4a6',
  400: '#6dcc95',
  500: '#56c485',
  600: '#41ba75',
  700: '#39a467',
  800: '#318d58',
  900: '#29764a',
  A100: '#ffffff',
  A200: '#bcf7d5',
  A400: '#6fde9e',
  A700: '#64d092',
  contrastDefaultColor: 'light',
}

export const teal = {
  50: '#9adcec',
  100: '#5ac5e0',
  200: '#2bb4d7',
  300: '#1e839c',
  400: '#196e83',
  500: '#145869',
  600: '#0f424f',
  700: '#0a2d36',
  800: '#05171c',
  900: '#000202',
  A100: '#7ee4fe',
  A200: '#19cffd',
  A400: '#0f84a1',
  A700: '#146c82',
  contrastDefaultColor: 'light',
}

export const yellow = {
  50: '#ffffff',
  100: '#fff7bf',
  200: '#ffef87',
  300: '#ffe63f',
  400: '#ffe221',
  500: '#ffde02',
  600: '#e2c500',
  700: '#c4aa00',
  800: '#a59000',
  900: '#877500',
  A100: '#ffffff',
  A200: '#fff29b',
  A400: '#ffe535',
  A700: '#ffe11b',
  contrastDefaultColor: 'dark',
}

export const orange = {
  50: '#fff2e0',
  100: '#ffdeb3',
  200: '#ffc980',
  300: '#ffb34d',
  400: '#ffa226',
  500: '#ff9200',
  600: '#ff8a00',
  700: '#ff7f00',
  800: '#ff7500',
  900: '#ff6300',
  A100: '#ffffff',
  A200: '#fff6f2',
  A400: '#ffd4bf',
  A700: '#ffc3a6',
  contrastDefaultColor: '#fff',
}

export const black = {
  50: '#e0e0e0',
  100: '#b3b3b3',
  200: '#808080',
  300: '#4d4d4d',
  400: '#262626',
  500: '#000000',
  600: '#000000',
  700: '#000000',
  800: '#000000',
  900: '#000000',
  A100: '#a6a6a6',
  A200: '#8c8c8c',
  A400: '#737373',
  A700: '#666666',
  contrastDefaultColor: 'light',
}
