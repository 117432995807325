import { createTheme } from '@material-ui/core/styles'
import { black, orange, teal } from './colors'

const theme = createTheme({
  palette: {
    // primary: {
    //   light: orange[300],
    //   main: orange[500],
    //   dark: orange[600],
    //   contrastText: '#fff',
    // },
    // secondary: {
    //   light: black[300],
    //   main: black[500],
    //   dark: black[700],
    // },
    primary: {
      light: '#418595',
      main: '#005867',
      dark: '#002f3d',
      contrastText: '#fff',
    },
    secondary: {
      light: '#6bf9b9',
      main: '#2bc589',
      dark: '#00935c',
      contrastText: '#fff',
    },
  },
  overrides: {
    MuiTooltip: {
      popper: {
        opacity: 1,
        maxWidth: 420,
      },
      tooltip: {
        backgroundColor: 'white',
        color: teal[700],
        border: '1px solid #dadde2',
        boxShadow: `0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)`,
        fontSize: 14,
        padding: 12,
      },
      arrow: {
        color: 'white',
      },
    },
    MuiTab: {
      // label: {
      //   fontSize: '16px',
      //   textTransform: 'uppercase',
      // },
    },
    MuiTabs: {
      indicator: {
        height: '5px',
        borderRadius: '3px 3px 0px 0px',
      },
    },
    MuiButton: {
      contained: { boxShadow: 'none' },
    },
    MuiListSubheader: {
      root: {
        // textTransform: 'uppercase',
        fontWeight: '600',
        fontSize: '13px',
      },
    },
  },
  typography: {
    fontFamily: [
      'Suisse',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    button: {
      textTransform: 'none',
    },
    useNextVariants: true,
  },
})

const emotionTheme = {
  colors: {
    primary: theme.palette.primary,
    secondary: theme.palette.secondary,
  },
}

if (process.env.NODE_ENV !== 'production' && typeof window !== 'undefined') {
  window.__emotionTheme = emotionTheme
}

export { theme as muiTheme, emotionTheme }
