import { useEffect } from 'react'

import i18n from 'i18next'
import { I18nextProvider, initReactI18next } from 'react-i18next'
import moment from 'moment'
import 'moment/locale/sv'

import { FALLBACK_LOCALE, SUPPORTED_LOCALES } from '~/lib/constants'

const translations = {
  en: require('~/locales/en.json'),
}

i18n.use(initReactI18next).init({
  lng: 'en',
  resources: translations,
  fallbackLng: FALLBACK_LOCALE,
  supportedLngs: SUPPORTED_LOCALES,
  interpolation: {
    escapeValue: false,
  },
  react: {
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    useSuspense: true,
  },
})

export default function I18n({ children }) {
  useEffect(() => {
    moment.locale('en')
  }, [i18n.language])

  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
}
