import * as Sentry from '@sentry/browser'
import { MuiThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline/'

import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'

import { ApolloProvider } from '@apollo/client'
import { client } from './src/apollo'

import I18n from '~/lib/i18n'
import { muiTheme, emotionTheme } from '~/lib/theme'
import { TunnelProvider } from 'react-tunnels'

import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'

import '~/styles/index.css'

export const wrapRootElement = ({ element }) => {
  Sentry.init({
    enabled: ['stage', 'prod'].includes(process.env.EQL_ENV),
    environment: process.env.EQL_ENV,
    dsn: process.env.GATSBY_SENTRY_DSN,
    release: process.env.EQL_VER,
  })

  return (
    <>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <TunnelProvider>
          <EmotionThemeProvider theme={emotionTheme}>
            <MuiThemeProvider theme={muiTheme}>
              <CssBaseline />
              <ApolloProvider client={client}>
                <I18n>{element}</I18n>
              </ApolloProvider>
            </MuiThemeProvider>
          </EmotionThemeProvider>
        </TunnelProvider>
      </MuiPickersUtilsProvider>
    </>
  )
}
