import { ApolloClient, InMemoryCache } from '@apollo/client'
import { typePolicies, offsetLimitPagination } from '@equipmentloop/apollo'

import { initialState, initialStateQuery } from './state'
import { link } from './links'

const cache = new InMemoryCache({
  typePolicies: {
    ...typePolicies,
    Query: {
      ...typePolicies.Query,
      orderedItem(existingData, { args, toReference }) {
        return (
          existingData ||
          toReference({ __typename: 'OrderedItem', id: args.id })
        )
      },
      fields: {
        users: offsetLimitPagination([
          'reverseSort',
          'searchString',
          'archived',
          'sortOrder',
        ]),
        projects: offsetLimitPagination([
          'companyId',
          'searchString',
          'sortOrder',
          'reverseSort',
          'includeArchivedProjects',
          'includeDemoProjects',
          'hasCoordinates',
        ]),
      },
    },
  },
})

export const client = new ApolloClient({
  link,
  cache,
})

client.writeQuery({
  query: initialStateQuery,
  data: initialState,
})
