import { gql } from '@apollo/client'

export const initialState = {
  itemSort: {
    __typename: 'ItemSort',
    sortOrder: 'description',
    reverseSort: false,
  },
  itemFilter: {
    __typename: 'ItemFilter',
    projectId: 'undefined',
    companyId: 'undefined',
  },
  selectedItemIds: [],
  selectedItemsOpen: false,
}

export const initialStateQuery = gql`
  query InitialCacheData {
    itemSort {
      sortOrder
      reverseSort
    }
    itemFilter {
      projectId
      companyId
    }
    selectedItemIds
    selectedItemsOpen
  }
`
