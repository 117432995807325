export const itemColors = {
  current: '#145769',
  due: '#FF9200',
  done: '#56C485',
  returned: '#9B9B9B',
}

export const mapboxPublicKey =
  'pk.eyJ1IjoiYWxiaW5la2IiLCJhIjoiY2p6cDFuODdqMDBoZjNjcGZ3cGMwcDYweSJ9.ylK1PhyHNrXXxJOA80qfZA'

export const userActivityDataIntervals = [
  {
    label: 'Daily',
    value: 'day',
  },
  {
    label: 'Weekly',
    value: 'week',
  },
  {
    label: 'Monthly',
    value: 'month',
  },
  {
    label: 'Yearly',
    value: 'year',
  },
]

export const isEQLProduction = process.env.EQL_ENV === 'prod'
export const isEQLStaging = process.env.EQL_ENV === 'stage'

/** Center of Sweden coordinates */
export const DEFAULT_COORDINATES = {
  longitude: 16.971,
  latitude: 63.579,
}

export const SUPPORTED_LOCALES = ['en']
export const FALLBACK_LOCALE = 'en'
