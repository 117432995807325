export const isLat = (num) => isFinite(num) && Math.abs(num) <= 90
export const isLng = (num) => isFinite(num) && Math.abs(num) <= 180

export const getContinueUrl = (location) => {
  const CONTINUE_BLACKLIST = ['/login', '/']

  if (
    location &&
    location.pathname &&
    !CONTINUE_BLACKLIST.includes(location.pathname)
  ) {
    const continueUrl = encodeURIComponent(
      `${window.origin}${location.pathname}${location.search || ''}`,
    )

    return `?continueUrl=${continueUrl}`
  }

  return `?continueUrl=${encodeURIComponent(window.origin)}`
}
