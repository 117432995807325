import { from } from '@apollo/client'
import { RetryLink } from '@apollo/client/link/retry'
// import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { HttpLink } from '@apollo/client/link/http'

import { getContinueUrl } from '~/lib/helpers'

import {
  createErrorLink,
  createLangaugeLink,
  authLink,
} from '@equipmentloop/apollo'

// FIXME:
const languageLink = createLangaugeLink('sv', 'pwa-admin')

const retryLink = new RetryLink({
  delay: {
    initial: 500,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 1,
    retryIf: (error, _operation) => !!error,
  },
})

const errorLink = createErrorLink(async () => {
  window.location.replace(
    process.env.GATSBY_EQL_LOGIN + '/logout' + getContinueUrl(window.location),
  )
})

// const batchHttpLink = new BatchHttpLink({
const httpLink = new HttpLink({
  uri: process.env.GATSBY_GRAPHQL_ENDPOINT,
  credentials: 'include',
  fetchOptions: { mode: 'cors' },
})

export const link = from([
  languageLink,
  authLink,
  retryLink,
  errorLink,
  // batchHttpLink,
  httpLink,
])
